#root{
  height: 100vh;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.alertBox{
  display: flex;
  justify-content: center;
  /* margin-left: 50%; */
}

.link-btn{
  background-color: #17a2b8 !important;
}

.resend-layout{
  height: 100%;
  flex-direction: column;
}

.resend-layout input{
  border: none;
  border-bottom: 1px solid #ced4da;
}

.resend-layout h4,.resend-layout h5{
  color : #888888;
}

.resend-layout .input-group-img{
  padding: 5px;
}

.error-text{
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
}

.div-center{
  display: flex;
  justify-content: center;
}

